import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        'selectOutcomeIndicator',
        'outcomeDescription'
    ]

    outcomeChanged(event) {
        const selectedId = event.target.value
        this.fetchDescription(selectedId)
    }

    fetchDescription(id) {
        const base_url = new URL(window.location.href)
        const url = base_url + `/outcome_indicator_description?outcome_indicator_id=${id}`
        fetch(url)
            .then((res) => res.json())
            .then((data) => this.setDescription(data))
    }

    setDescription(data) {
        this.outcomeDescriptionTarget.innerHTML = data['description']
    }
}